import React, { Component } from "react";
import Footer from '../components/footer';
import { createGlobalStyle } from 'styled-components';
import { NotificationManager } from 'react-notifications';
import axios from 'axios';
import { UserContext } from "../app";
import web3 from 'web3';

const GlobalStyles = createGlobalStyle`
  header#myHeader.navbar.sticky.white {
    background: #403f83;
    border-bottom: solid 1px #403f83;
  }
  header#myHeader.navbar .search #quick_search{
    color: #fff;
    background: rgba(255, 255, 255, .1);
  }
  header#myHeader.navbar.white .btn, .navbar.white a, .navbar.sticky.white a{
    color: #fff;
  }
  header#myHeader .dropdown-toggle::after{
    color: rgba(255, 255, 255, .5);
  }
  header#myHeader .logo .d-block{
    display: none !important;
  }
  header#myHeader .logo .d-none{
    display: block !important;
  }
  .mainside{
    .logout{
      display: flex;
      align-items: center;
    }
  }
  @media only screen and (max-width: 1199px) {
    .navbar{
      background: #403f83;
    }
    .navbar .menu-line, .navbar .menu-line1, .navbar .menu-line2{
      background: #fff;
    }
    .item-dropdown .dropdown a{
      color: #fff !important;
    }
  }
`;

export default class Verify extends Component {

  constructor() {
    super();
    this.onChange = this.onChange.bind(this);
    this.state = {
      selected_file: '',
      title: '',
      description: '',
      owner: '',
      registerTime: ''
    };
  }

  componentDidMount() {
    Verify.contextType = UserContext;
  }

  onChange(e) {
    const file = e.target.files[0];
    document.getElementById("file_name").style.display = "none";
    if (file) {
      this.setState({ selected_file: file, title: '', description: '', owner: '', registerTime: '' });
    }
  }

  onVerify = async (e) => {
    e.preventDefault();
    let walletAddress = this.context.walletAddress;
    if(walletAddress === undefined || walletAddress === '')
    {
      NotificationManager.warning("You should connect wallet.", "Warning");
      return; 
    }
    const { selected_file } = this.state;
    const hash = document.getElementById("item_hash").value;
    if(selected_file === '')
    {
      NotificationManager.warning("Upload file to Verify", "Warning");
      return;
    }

    if(hash === '')
    {
      NotificationManager.warning("Input hash value correctly", "Warning");
      return;
    }
    
    window.ethereum.request({
      method: 'eth_sendTransaction',
      params: [{
        from: walletAddress,
        to: "0xB0cD0F92B4471faACAe06136EBD4Ed0D872D8bBE",
        value: web3.utils.toHex(web3.utils.toWei("1")),
      }],
    })
    .then(() => {
      var reader = new FileReader();
      reader.readAsText(selected_file);
      reader.onloadend = async () => {
        const base64String = reader.result;
        const responseByUser = await axios.post('https://api.peeke.ch/verify',
          {
              file: base64String,
              hash: hash,
              account: walletAddress
          },
          {
              headers: {
                'Content-Type': 'application/json'
              }
          }
        );
        if(responseByUser.status === 200) {
          this.setState({ title: responseByUser.data.response.title, description: responseByUser.data.response.description, owner: responseByUser.data.response.owner, registerTime: responseByUser.data.response.registerTime });
          NotificationManager.success("Successfully Verified", "Success");
        }
        else
        {
          NotificationManager.warning("Sorry, Not Verified", "Warning");
        }
      };
    })
    .catch((e) => {
      NotificationManager.warning("Sorry, Not sent correctly.", "Warning");
    })
  }

  state = {
     isActive:false  
  }

  unlockClick = ()=>{
    this.setState({ isActive: true });
  }
  unlockHide = () => {
    this.setState({ isActive: false });
  };

render() {
    return (
      <div>
      <GlobalStyles/>

        <section className='jumbotron breadcumb no-bg' style={{backgroundImage: `url(${'./img/background/subheader.jpg'})`}}>
          <div className='mainbreadcumb'>
            <div className='container'>
              <div className='row m-10-hor'>
                <div className='col-12'>
                  <h1 className='text-center'>Verify</h1>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className='container'>

        <div className="row">
          <div className="col-lg-10 offset-lg-1 mb-5">
              <form id="form-create-item" className="form-border">
                  <div className="field-set">
                      <h5>Upload file to verify</h5>
                      <div className="d-create-file">
                          <p id="file_name">Upload file to verify.</p>
                          <p key={this.state.selected_file.name}>{this.state.selected_file.name}</p>
                          <div className='browse'>
                            <input type="button" id="get_file" className="btn-main" value="Browse"/>
                            <input id='upload_file' type="file" onChange={this.onChange} />
                          </div>
                      </div>
                      <div className="spacer-20"></div>
                      <h5>Hash Value</h5>
                      <input type="text" name="item_hash" id="item_hash" className="form-control" placeholder="e.g. Qmdcs9pUmFABW66NvxuuSAmXpFopZYthdnu1BVmiKhp6v9" />
                      <div className="spacer-10"></div>
                      <input type="button" id="submit" className="btn-main" onClick={this.onVerify} value="Verify"/>
                      <div className="spacer-20"></div>
                      { this.state.owner !== '' && (
                        <div>
                          <h5>Owner:</h5> {this.state.owner}
                          <h5>Title:</h5> {this.state.title}
                          <h5>Description:</h5> {this.state.description}
                          <h5>RegisterTime:</h5> {this.state.registerTime}
                        </div>
                      )}
                  </div> 
              </form>
          </div>
      </div>
      </section>
        <Footer />
      </div>
   );
  }
}