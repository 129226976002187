import React, { useEffect, useState, useContext } from "react";
import Breakpoint, { BreakpointProvider, setDefaultBreakpoints } from "react-socks";
import { Link } from '@reach/router';
import useOnclickOutside from "react-cool-onclickoutside";
import { useDispatch } from "react-redux";
import {NotificationManager} from 'react-notifications';
import { connectWallet } from '../../core/nft/interact';
import { UserContext } from "../app";

setDefaultBreakpoints([
  { xs: 0 },
  { l: 1199 },
  { xl: 1200 }
]);

const NavLink = props => (
  <Link
    {...props}
    getProps={({ isCurrent }) => {
      // the object returned here is passed to the
      // anchor element's props
      return {
        className: isCurrent ? 'active' : 'non-active',
      };
    }}
  />
);

const Header = () => {
  const [openMenu, setOpenMenu] = React.useState(false);
  const [openMenu2, setOpenMenu2] = React.useState(false);
  const [isConnected, SetConnected] = React.useState(false);
  const { SetWalletAddress } = useContext(UserContext);
  const handleBtnClick = () => {
    setOpenMenu(!openMenu);
  };
  const handleBtnClick2 = () => {
    setOpenMenu2(!openMenu2);
  };
  
  const closeMenu = () => {
    setOpenMenu(false);
  };

  const closeMenu2 = () => {
    setOpenMenu2(false);
  };

  const ref = useOnclickOutside(() => {
    closeMenu();
  });

  const [showmenu, btn_icon] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    const header = document.getElementById("myHeader");
    const totop = document.getElementById("scroll-to-top");
    const sticky = header.offsetTop;
    const scrollCallBack = window.addEventListener("scroll", () => {
      btn_icon(false);
      if (window.pageYOffset > sticky) {
        header.classList.add("sticky");
        totop.classList.add("show");

      } else {
        header.classList.remove("sticky");
        totop.classList.remove("show");
      } if (window.pageYOffset > sticky) {
        closeMenu();
      }
    });
    return () => {
      window.removeEventListener("scroll", scrollCallBack);
    };
  }, [dispatch]);

  const connectWalletPressed = async () => {
    const walletResponse = await connectWallet();
    if(walletResponse.address === '')
    {
      SetConnected(false);
      SetWalletAddress('');
      NotificationManager.warning('Please Install Metamask or Add Polygon Chain on your wallet.', 'Warning');
    }
    else
    {
      SetConnected(true);
      SetWalletAddress(walletResponse.address);
      NotificationManager.success('Connected Successfully.', 'Success');
    }
};

  return (
    <header id="myHeader" className='navbar white'>
      <div className='container'>
        <div className='row w-100-nav'>
          <div className='logo px-0'>
            <div className='navbar-title navbar-item'>
              <NavLink to="/">
                <img
                  src="/img/peeke_logo.png"
                  className="img-fluid d-block"
                  alt="#"
                />
                <img
                  src="/img/peeke_logo.png"
                  className="img-fluid d-3"
                  alt="#"
                />
                <img
                  src="/img/peeke_logo_mark_only.png"
                  className="img-fluid d-4"
                  alt="#"
                />
                <img
                  src="/img/peeke_logo_light.png"
                  className="img-fluid d-none"
                  alt="#"
                />
              </NavLink>
            </div>
          </div>

          <BreakpointProvider>
            <Breakpoint xl>
              <div className='menu'>
                <div className='navbar-item' ref={ref} onMouseEnter={handleBtnClick} onMouseLeave={closeMenu}>
                  <NavLink to="/home"> Home </NavLink>
                </div>
                <div className='navbar-item'>
                  <div className="dropdown-custom dropdown-toggle btn"
                    onMouseEnter={handleBtnClick2} onMouseLeave={closeMenu2}>
                    Timestamp
                    <span className='lines'></span>
                    {openMenu2 && (
                      <div className='item-dropdown'>
                        <div className="dropdown" onClick={closeMenu2}>
                          <NavLink to="/timestamp">Register</NavLink>
                          <NavLink to="/verify">Verify</NavLink>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </Breakpoint>
          </BreakpointProvider>

          {!isConnected && (
          <div className='mainside'>
            <div className='connect-wal'>
              <button id="walletButton" className="btn-main" onClick={connectWalletPressed}>
                Connect Wallet
              </button>
            </div>
          </div>
          )}
        </div>

        <button className="nav-icon" onClick={() => btn_icon(!showmenu)}>
          <div className="menu-line white"></div>
          <div className="menu-line1 white"></div>
          <div className="menu-line2 white"></div>
        </button>

      </div>
    </header>
  );
}
export default Header;