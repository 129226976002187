import React, { useEffect } from "react";
import Reveal from 'react-awesome-reveal';
import { keyframes } from "@emotion/react";
import { useDispatch} from "react-redux"; 
import { fetchAuthorListAction } from "../../store/actions/thunks";

const fadeInUp = keyframes`
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
`;
const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const Slidermain= () => {
  
  const dispatch = useDispatch();
  
  useEffect(() => {   
      dispatch(fetchAuthorListAction());
  }, [dispatch]);

return(
 <div className="container">
    <div className="row align-items-center">
          <div className="col-md-6">
              <div className="spacer-single"></div>
              <Reveal className='onStep' keyframes={fadeInUp} delay={0} duration={600} triggerOnce>
              <h6 className=""><span className="text-uppercase color">Peeke Timestamp</span></h6>
              </Reveal>
              <div className="spacer-10"></div>
              <Reveal className='onStep' keyframes={fadeInUp} delay={300} duration={600} triggerOnce>
              <h1 className="">Stamp or Verify everything you want.</h1>
              </Reveal>
              <Reveal className='onStep' keyframes={fadeInUp} delay={600} duration={600} triggerOnce>
              <p className=" lead">
              Peeke Timestamp proves that some data existed prior to some point in time. Peeke Timestamp defines a set of operations for creating provable timestamps and later independently verifying them. At the time of writing timestamping on the Polygon blockchain is supported, the format is flexible enough to support a variety of methods.<br></br><br></br>
              Anyone could realize a timestamp with the permissionless blockchain by paying transaction fees, for your convenience we offer calendar servers that perform this operation for you. These servers are free to use and they don't require any registration or api key.
              </p>
              </Reveal>
          </div>
          <div className="col-md-6 xs-hide">
            <Reveal className='onStep' keyframes={fadeIn} delay={900} duration={1500} triggerOnce>
              <img src="./img/misc/nft.png" className="lazy img-fluid" alt=""/>
            </Reveal>
          </div>
      </div>
    </div>
);
}
export default Slidermain;