import React , { useEffect } from "react";
import { useDispatch } from 'react-redux';
import SliderMain from '../components/SliderMain';
import FeatureBox from '../components/FeatureBox';
import Footer from '../components/footer';
import { fetchAuthorListAction } from "../../store/actions/thunks";

const Home= () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchAuthorListAction());
  }, );
  
  return (
    <div>
        <section className="jumbotron breadcumb no-bg h-vh" style={{backgroundImage: `url(${'./img/bg-shape-1.jpg'})`}}>
          <SliderMain/>
        </section>
        <section className='container no-top no-bottom'>
          <FeatureBox/>
        </section>
      <Footer />
    </div>
  );
}
export default Home;