import React from 'react';
import { Router, Location, Redirect } from '@reach/router';
import ScrollToTopBtn from './menu/ScrollToTop';
import Header from './menu/header';
import Home from './pages/home';
import Timestamp from './pages/timestamp';
import Verify from './pages/verify';
import { createGlobalStyle } from 'styled-components';
import { NotificationContainer } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { useState, createContext } from 'react'
export const UserContext = createContext(null)
const GlobalStyles = createGlobalStyle`
  :root {
    scroll-behavior: unset;
  }
`;

export const ScrollTop = ({ children, location }) => {
  React.useEffect(() => window.scrollTo(0,0), [location])
  return children
}

const PosedRouter = ({ children }) => (
  <Location>
    {({ location }) => (
      <div id='routerhang'>
        <div key={location.key}>
          <Router location={location}  >
            {children}
          </Router>
        </div>
      </div>
    )}
  </Location>
);

const App = () => {
  const [walletAddress, SetWalletAddress] = useState('');
  return (
    <UserContext.Provider value={{ walletAddress, SetWalletAddress }}>
      <div className="wraper">
        <GlobalStyles />
        <Header/>
          <PosedRouter>
            <ScrollTop path="/">
            <Home exact path="/">
              <Redirect to="/home" />
            </Home>
            <Timestamp path="/timestamp" />
            <Verify path="/verify" />
            </ScrollTop>
          </PosedRouter>
        <ScrollToTopBtn />
        <NotificationContainer/>
      </div>
    </UserContext.Provider>
  )
}
export default App;