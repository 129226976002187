import React, { Component } from "react";
import Footer from '../components/footer';
import { createGlobalStyle } from 'styled-components';
import {NotificationManager} from 'react-notifications';
import axios from 'axios';
import { UserContext } from "../app";
import web3 from 'web3';

const GlobalStyles = createGlobalStyle`
  header#myHeader.navbar.sticky.white {
    background: #403f83;
    border-bottom: solid 1px #403f83;
  }
  header#myHeader.navbar .search #quick_search{
    color: #fff;
    background: rgba(255, 255, 255, .1);
  }
  header#myHeader.navbar.white .btn, .navbar.white a, .navbar.sticky.white a{
    color: #fff;
  }
  header#myHeader .dropdown-toggle::after{
    color: rgba(255, 255, 255, .5);
  }
  header#myHeader .logo .d-block{
    display: none !important;
  }
  header#myHeader .logo .d-none{
    display: block !important;
  }
  .mainside{
    .logout{
      display: flex;
      align-items: center;
    }
  }
  @media only screen and (max-width: 1199px) {
    .navbar{
      background: #403f83;
    }
    .navbar .menu-line, .navbar .menu-line1, .navbar .menu-line2{
      background: #fff;
    }
    .item-dropdown .dropdown a{
      color: #fff !important;
    }
  }
`;

export default class Timestamp extends Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.state = {
      selected_file: '',
      hash: ''
    };
  }

  componentDidMount() {
    Timestamp.contextType = UserContext;
  }
  
  onChange(e) {
    const file = e.target.files[0];
    document.getElementById("file_name").style.display = "none";
    if (file) {
      this.setState({ selected_file: file, hash: '' });
    }
  }

  onRegister = async (e) => {
    e.preventDefault();
    let walletAddress = this.context.walletAddress;
    if(walletAddress === undefined || walletAddress === '')
    {
      NotificationManager.warning("You should connect wallet.", "Warning");
      return; 
    }

    const { selected_file } = this.state;
    const title = document.getElementById("item_title").value;
    const desc = document.getElementById("item_desc").value;
    if(selected_file === '')
    {
      NotificationManager.warning("Upload file to Register.", "Warning");
      return;
    }

    if(title === '')
    {
      NotificationManager.warning("You should input title.", "Warning");
      return;
    }
    if(desc === '')
    {
      NotificationManager.warning("You should input description.", "Warning");
      return;
    }

    window.ethereum.request({
      method: 'eth_sendTransaction',
      params: [{
        from: walletAddress,
        to: "0xB0cD0F92B4471faACAe06136EBD4Ed0D872D8bBE",
        value: web3.utils.toHex(web3.utils.toWei("1")),
      }],
    })
    .then(() => {
      const timestamp = new Date();
      var reader = new FileReader();
      reader.readAsText(selected_file);
      reader.onloadend = async () => {
        const base64String = reader.result;
        const responseByUser = await axios.post('https://api.peeke.ch/register',
          {
              file: base64String,
              account: walletAddress,
              title: title,
              desc: desc,
              timestamp: timestamp
          },
          {
              headers: {
                'Content-Type': 'application/json'
              }
          }
        );
        if(responseByUser.status === 200) {
          this.setState({ hash: responseByUser.data.response });
          NotificationManager.success("Successfully Registered", "Success");
        }
        else
        {
          if(responseByUser.data.response === 'already')
          {
            NotificationManager.warning("Already registered.", "Warning");
          }
          else
          {
            NotificationManager.warning("Sorry, Not Registered", "Warning");
          }
        }
      };
    })
    .catch((e) => {
      NotificationManager.warning("Sorry, Not sent correctly.", "Warning");
    })
  }

  state = {
     isActive:false  
  }

  unlockClick = ()=>{
      this.setState({ isActive: true })
  }
  unlockHide = () => {
    this.setState({ isActive: false });
  };

  

  render() {
    return (
      <div>
      <GlobalStyles/>
        <section className='jumbotron breadcumb no-bg' style={{backgroundImage: `url(${'./img/background/subheader.jpg'})`}}>
          <div className='mainbreadcumb'>
            <div className='container'>
              <div className='row m-10-hor'>
                <div className='col-12'>
                  <h1 className='text-center'>Register</h1>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className='container'>

        <div className="row">
          <div className="col-lg-10 offset-lg-1 mb-5">
              <form id="form-create-item" className="form-border">
                  <div className="field-set">
                      <h5>Upload file</h5>
                      <div className="d-create-file">
                          <p id="file_name">Upload files to register.</p>
                          <p key={this.state.selected_file.name}>{this.state.selected_file.name}</p>
                          <div className='browse'>
                            <input type="button" id="get_file" className="btn-main" value="Browse"/>
                            <input id='upload_file' type="file" onChange={this.onChange} />
                          </div>
                      </div>
                      <div className="spacer-20"></div>
                      <h5>Title</h5>
                      <input type="text" name="item_title" id="item_title" className="form-control" placeholder="e.g. Crypto Funk" />
                      <div className="spacer-10"></div>
                      <h5>Description</h5>
                      <textarea data-autoresize name="item_desc" id="item_desc" className="form-control" placeholder="e.g. 'This is very limited item'"></textarea>
                      <div className="spacer-10"></div>
                      <input type="button" id="submit" className="btn-main" onClick={this.onRegister} value="Register"/>
                      <div className="spacer-20"></div>
                      { this.state.hash !== '' && (
                        <div>
                         <h5>HashValue:</h5> {this.state.hash}
                        </div>
                      )}
                  </div> 
              </form>
          </div>
      </div>
      </section>
        <Footer />
      </div>
   );
  }
}
